<template>
    <div>
        <v-container>
            <v-card style="background-color: #F5F5F5" elevation="3">
                <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                    <span v-if="isNew">Nuevo</span>
                    <span v-if="!isNew">Editar</span>
                    control de dinero
                </div>

                <v-form
                  ref="form"
                  autocomplete="off"
                >
                <!-- FORMULARIO CONTROL -->
                <v-row>
                    <v-col class="px-7 mt-4 mb-n7" cols="7" sm="4" md="3" lg="3" xl="2">
                        <DateFind label="Fecha" v-model="din.DinTmp" @change="saveData(false, false)"></DateFind>
                    </v-col>
                </v-row>
                <v-row v-if="(DefaultElemCatId == null || DefaultElemCatId == 0) && isNew">
                    <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                        <ElemCatFind 
                            ref="elemCatFind"
                            v-model="din.ElemCat"
                            :elemCatIdParent="2"
                            :orderById="true"
                            label="Tipo"
                            :hideDetails="true"
                            @change="onChangeElemCat">
                        </ElemCatFind>
                    </v-col>
                </v-row>
                <v-row v-if="!$store.state.esTienda || perIdAux == null || perIdAux == 0">
                    <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                        <PerFind class="mb-n7" ref="dinPerFind" v-model="din.Per" label="Controlado por" :perCatIdAnyLevel="101" @change="saveData(false, false)"></PerFind>
                    </v-col>
                </v-row>

                <!-- DINELEM -->
                <v-row class="mt-2">
                    <v-col class="px-7" cols="12" sm="12" md="10" lg="10" xl="8">
                        <!-- Boton añadir elemento -->
                        <v-btn
                            :loading="loading"
                            :disabled="editNewDinElem"
                            @click="onBtnNuevoDinElem"
                            color="primary"
                            small
                            class="mb-2">
                            Añadir
                        </v-btn>

                        <v-data-table
                            :headers="dinElemHeaders"
                            :items="dinElemDataRows"
                            :options.sync="dinElemOptions"
                            :footer-props="{itemsPerPageOptions: [100]}"
                            :server-items-length="dinElemTotalDataRows"
                            :loading="dinElemLoading"
                            style="overflow: scroll"
                            mobile-breakpoint="0"
                            class="elevation-1 dinElemDt"
                            dense>
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.DinElemCant="{ item }">
                                <v-text-field
                                    class="my-2"
                                    outlined dense
                                    hide-details
                                    type="number"
                                    min="0"
                                    v-model="item.DinElemCant"
                                    label="Cantidad"
                                    @change="saveData(false, false)"
                                    @blur="onBlurFixCantidad(item, false)">
                                </v-text-field>
                            </template>
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.DinElemDif="{ item }">
                                <v-text-field
                                    class="my-2"
                                    outlined dense
                                    hide-details
                                    type="number"
                                    min="0"
                                    v-model="item.DinElemDif"
                                    label="Diferencia"
                                    @change="saveData(false, false)"
                                    @blur="onBlurFixCantidad(item, true)">
                                </v-text-field>
                            </template>
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.Opt="{ item }">
                                <v-btn
                                color="error"
                                class="mr-2 my-1"
                                small
                                @click.stop="onBtnShowDinElemDeleteModal(item)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                            <!-- NUEVO ELEMENTO FORMULARIO -->
                            <template v-if="editNewDinElem" slot="body.append">
                                <tr class="new-row">
                                    <td>
                                        <ElemFind 
                                            ref="newElemFind" 
                                            v-model="newDinElem.Elem" 
                                            :elemCatId="din.ElemCat != null ? din.ElemCat.ElemCatId : null"
                                            label="Elemento" 
                                            :hideDetails="true">
                                        </ElemFind>
                                    </td>
                                    <td>
                                        <v-text-field
                                            outlined dense
                                            hide-details
                                            type="number"
                                            min="0"
                                            v-model="newDinElem.DinElemCant"
                                            label="Cantidad"
                                            @blur="onBlurFixCantidad(null, false)">
                                        </v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            outlined dense
                                            hide-details
                                            type="number"
                                            v-model="newDinElem.DinElemDif"
                                            label="Diferencia"
                                            @blur="onBlurFixCantidad(null, true)">
                                        </v-text-field>
                                    </td>
                                    <td style="min-width: 250px" align ="right">
                                        <v-btn
                                            :loading="loading"
                                            :disabled="!isValidDinElem(newDinElem.Elem)"
                                            @click="onBtnSaveDinElem"
                                            color="primary"
                                            class="mt-2 mb-4 mr-2"
                                            small>
                                            Aceptar
                                        </v-btn>
                                        
                                        <v-btn
                                            @click="onBtnCancelDinElem"
                                            class="mt-2 mb-4 mr-2"
                                            small>
                                            Cancelar
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="px-7 mt-2" cols="12" sm="12" md="10" lg="10" xl="8">
                        <v-textarea
                            class="mt-0 mb-n7"
                            outlined
                            auto-grow
                            v-model="din.DinObs"
                            label="Observaciones"
                            @change="saveData(false, false)">
                        </v-textarea>
                    </v-col>
                </v-row>
                  
                <!-- ACEPTAR / SALIR / BORRAR -->
                <v-row class="mt-4" justify="center">
                    <v-btn
                        class="mt-2 mb-4 mr-2"
                        style="width:100px"
                        color="primary"
                        :loading="loading"
                        @click="saveData(true, true)">
                        Aceptar
                    </v-btn>
                    
                    <v-btn
                        class="mt-2 mb-4 mr-2"
                        style="width:100px"
                        @click="goToList">
                        Salir
                    </v-btn>

                    <v-btn v-if="din.DinId != 0"
                        color="error"
                        class="mt-2 mb-4"
                        style="width:100px"
                        @click="onBtnShowDeleteModal()">
                        Borrar
                    </v-btn>
                </v-row>
                </v-form>
            </v-card>
        </v-container>

        <!-- Modal de borrado -->
        <v-dialog
            v-model="showDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR CONTROL DE DINERO
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar el control de dinero? Esta acción no se podrá deshacer.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="loading"
                        color="primary"
                        @click="onBtnDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Borrar detalle -->
        <v-dialog
            v-model="showDinElemDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR ELEMENTO
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar el elemento?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="loading"
                        color="primary"
                        @click="onBtnDinElemDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelDinElemDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import DateFind from "../components/DateFind.vue";
import ElemFind from "../components/ElemFind.vue";
import ElemCatFind from "../components/ElemCatFind.vue";
import PerFind from "../components/PerFind.vue";
export default ({
    components: { DateFind, ElemFind, ElemCatFind, PerFind },
    computed: {
        ...mapState(['empId', 'perId', 'perIdAux', 'nombreUsuarioAux', 'urlRaiz', 'DefaultElemCatId', 'LoadDinElems'])
    },
    data () {
        return {
            loading: false,
            isNew: true,
            isValid: false,
            showDeleteDialog: false,
            showDinElemDeleteDialog: false,
            din: {
                DinId: 0,
                DirId: null,
                DinTmp: null,
                ElemCat: {},
                ElemCatId: null,
                Per: {},
                PerId: null,
                DinObs: ''
            },
            // Datatable detalle
            dinElemLoading: false,
            dinElemTotalDataRows: 0,
            dinElemDataRows: [],
            dinElemOptions: {itemsPerPage: 100},
            dinElemHeaders: [
                { text: 'Nombre', width:214, sortable: false, value: 'Elem.ElemNom' },
                { text: 'Cantidad', width:130, sortable: false, value: 'DinElemCant' },
                { text: 'Diferencia', width:130, sortable: false, value: 'DinElemDif' },
                { text: '', width:90, sortable: false, value: 'Opt', align: 'end' }
            ],
            // Edición detalle
            editNewDinElem: false, // Indica si se esta editando el nuevo detalle
            newDinElem: {}, // Datos del nuevo detalle
            selectedDinElem: null // Detalle seleccionado para borrar
        }
    },
    // watch: {
    //   din: {
    //     handler () {
    //         this.validate();
    //     },
    //     deep: true,
    //   }
    // },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      
      if (this.LoadDinElems == null) this.LoadDinElems = true;

      // Dir Init.
      const dir = this.$store.state.dir;
      if(dir != null && dir.DirId != null && dir.DirId != 0) {
        this.din.DirId = dir.DirId;
      }
      
      if(!this.isNew) {
        this.din.DinId = this.$route.params.id;
        this.loadData();
      } else {
        // Tipo init.
        const tipo = this.DefaultElemCatId;
        if(tipo != null && tipo != 0) {
          this.din.ElemCatId = tipo;
          this.loadDefaultElemCat(this.din.ElemCatId);
        }

        // Fecha init.
        const today = new Date();
        const todayString = today.getFullYear() + '-' + 
            String(today.getMonth() + 1).padStart(2, '0') + '-' +
            String(today.getDate()).padStart(2, '0');
        this.din.DinTmp = todayString;

        // Per init.
        if(this.perIdAux != null && this.perIdAux != 0) {
          this.din.PerId = this.perIdAux;
          this.din.Per = {
              PerId: this.perIdAux,
              PerNom: this.nombreUsuarioAux
          }
        }
      }
    },
    methods: {
      goToList() {
        this.$router.push('/control-dinero');
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_DIN',
            DinId: this.din.DinId,
            DirId: this.din.DirId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/din", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Din;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar el control de dinero.');
                this.goToList();
            }
          });
        })
      },
      getDefaultElemCatFromApi(elemCatId) {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_ELEMCAT_WITH_ELEMS',
            EmpId: this.empId,
            ElemCatId: elemCatId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/elemcat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.ElemCat;
            const elemList = result.data.Elem;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item,
                    elemList
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar el tipo.');
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        // Asignamos los IDs de los objetos hijos
        const dinElems = this.dinElemDataRows.map(m => {
          return {
            ElemId: m.ElemId,
            DinElemCant: m.DinElemCant,
            DinElemDif: m.DinElemDif
          };
        });

        // this.din.DirId = this.din.Dir != null ? this.din.Dir.DirId : null;
        this.din.PerId = this.din.Per != null ? this.din.Per.PerId : null;
        this.din.ElemCatId = this.din.ElemCat != null ? this.din.ElemCat.ElemCatId : null;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            Din: this.din,
            DinElems: dinElems
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/din", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar el control de dinero.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            DinId: this.din.DinId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/din", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al borrar el control de dinero.');
            }
          });
        })
      },
      //#endregion

      //#region Carga de datos
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Control de dinero no encontrado.');
              this.goToList();
              return;  
            }

            const date = new Date(data.item.DinTmp);
            const dateString = date.getFullYear() + '-' + 
            String(date.getMonth() + 1).padStart(2, '0') + '-' +
            String(date.getDate()).padStart(2, '0');
            data.item.DinTmp = dateString;

            this.din = data.item;

            this.dinElemDataRows = data.item.DinElem.map(m => {
              return {
                ElemId: m.Elem.ElemId,
                Elem: {
                  ElemNom: m.Elem.ElemNom
                },
                DinElemCant: Number(m.DinElemCant).toFixed(2),
                DinElemDif: Number(m.DinElemDif).toFixed(2)
              }
            });
            this.dinElemTotalDataRows = data.item.DinElem.length;
          });
      },
      loadDefaultElemCat(elemCatId) {
        this.getDefaultElemCatFromApi(elemCatId)
          .then(data => {
            if(data.item == null) {
              alert('Tipo de control no encontrado.');
              return;  
            }

            this.din.ElemCat = data.item;
            if(this.LoadDinElems) {
              this.dinElemDataRows = data.elemList.map(m => {
                return {
                  ElemId: m.ElemId,
                  Elem: {
                    ElemNom: m.ElemNom
                  },
                  DinElemCant: 0,
                  DinElemDif: 0
                }
              });
              this.dinElemTotalDataRows = data.elemList.length;
            }
          });
      },
      //#endregion

      //#region  Añadir detalle
      onBtnNuevoDinElem() {
        this.editNewDinElem = true;
        this.newDinElem = {
          Elem: null,
          DinElemCant: 0,
          DinElemDif: 0
        };
      },
      //#endregion

      //#region Guardado
      saveData(goToList = false, checkValidation = false) {
        this.validate(checkValidation);
        if(!this.isValid) { return; }
        this.postDataToApi().then(data => {
            if(data.item.DinId != null) {
                if(this.din.DinId == 0) {
                    this.din.DinId = data.item.DinId;
                }
                if(goToList) { this.goToList(); }
            }
            else {
                alert('Error al guardar el control de dinero.');
            }
        });
      },
      onBtnSaveDinElem() {
        if(!this.isValidDinElem(this.newDinElem.Elem)) { return; }
        
        const newDinElem = {
          ElemId: this.newDinElem.Elem.ElemId,
          Elem: {
            ElemNom: this.newDinElem.Elem.ElemNom
          },
          DinElemCant: this.newDinElem.DinElemCant,
          DinElemDif: this.newDinElem.DinElemDif
        };

        this.dinElemDataRows.push(newDinElem);
        this.dinElemTotalDataRows += 1;

        this.newDinElem = {};
        this.editNewDinElem = false;

        this.saveData();
      },
      onChangeElemCat() {
        this.dinElemDataRows = [];
        this.dinElemTotalDataRows = 0;
        
        if(this.din.ElemCat == null) { return; }
        this.getDefaultElemCatFromApi(this.din.ElemCat.ElemCatId)
          .then(data => {
            if(data.item == null) {
              alert('Tipo de control no encontrado.');
              return;
            }

            if(this.LoadDinElems) {
              this.dinElemDataRows = data.elemList.map(m => {
                return {
                  ElemId: m.ElemId,
                  Elem: {
                    ElemNom: m.ElemNom
                  },
                  DinElemCant: 0,
                  DinElemDif: 0
                }
              });
              this.dinElemTotalDataRows = data.elemList.length;
            }

            this.saveData();
          });
      },
      //#endregion

      //#region Click borrar
      onBtnDelete() {
        this.deleteDataFromApi().then(data => {
          if (data.item.Success) {
            this.showDeleteDialog = false;
            this.goToList();
          }
          else {
            alert(data.item.Message);
          }
        });
      },
      //#endregion

      //#region  Cancelar creación de detalle
      onBtnCancelDinElem() {
        this.editNewDinElem = false;
        this.newDinElem = {};
      },
      //#endregion

      //#region  Eliminar detalle
      removeDinElem(item) {
        var index = this.dinElemDataRows.indexOf(item);
        if (index != -1) {
          this.dinElemDataRows.splice(index, 1);
          this.dinElemTotalDataRows -= 1;
        }
      },
      onBtnDinElemDelete() {
        this.removeDinElem(this.selectedDinElem);
        this.selectedDinElem = null;
        this.showDinElemDeleteDialog = false;
        this.saveData();
      },
      //#endregion

      //#region Gestión de modales
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      onBtnShowDinElemDeleteModal(item) {
        this.selectedDinElem = item;
        this.showDinElemDeleteDialog = true;
      },
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      onBtnCancelDinElemDelete() {
        this.selectedDinElem = null;
        this.showDinElemDeleteDialog = false;
      },
      //#endregion
      

      //#region Validar formulario
      validate(checkValidation = false) {
          let isValid = true;
          let msg = '';

          if (this.dinElemDataRows.length == 0) { 
            msg = 'No puedes guardar un control de dinero sin ninguna línea en el detalle.';
            isValid = false; 
          }
          else if (this.din.DinTmp == null) { 
            msg = 'El campo Fecha es obligatorio.';
            isValid = false; 
          }
          else if (this.din.ElemCat == null || this.din.ElemCat.ElemCatId == null) { 
            msg = 'El campo Tipo es obligatorio.';
            isValid = false; 
          }
          else if (this.din.Per == null || this.din.Per.PerId == null) { 
            msg = 'El campo Controlado por es obligatorio.';
            isValid = false; 
          }

          if (!isValid && checkValidation) { alert(msg); }
          
          this.isValid = isValid;
          return isValid;
      },
      isValidDinElem(elem) {
        if (elem == null || elem.ElemId == null) { return false; }
        if (this.dinElemDataRows.map(m => m.ElemId).indexOf(elem.ElemId) >= 0) { return false; }
        return true;
      },
      // Control de los inputs de cantidad para mostrar 2 decimales
      onBlurFixCantidad(item, fixDif) {
        // Editando linea
        if (item != null) 
        {
            const index = this.dinElemDataRows.indexOf(item);

            if (index != -1) 
            {
                let valueToFix = fixDif ? this.dinElemDataRows[index].DinElemDif : this.dinElemDataRows[index].DinElemCant;
                valueToFix = Number(valueToFix).toFixed(2);

                if (fixDif) { this.dinElemDataRows[index].DinElemDif = valueToFix; }
                else  { this.dinElemDataRows[index].DinElemCant = valueToFix; }
            }
        }
        // Nueva linea
        else 
        {
            let valueToFix = fixDif ? this.newDinElem.DinElemDif : this.newDinElem.DinElemCant;
            valueToFix = Number(valueToFix).toFixed(2);

            if (fixDif) { this.newDinElem.DinElemDif = valueToFix; }
            else  { this.newDinElem.DinElemCant = valueToFix; }
        }
      }
      //#endregion
    } 
})
</script>
